import React from "react";
import './HeaderBtn.css'

function HeaderBtn (props) {
    return (
        <button className='header-btn' onClick={props.onClick} disabled={!!props.disabled}>
            {props.title}
        </button>
    )
}

export default HeaderBtn;