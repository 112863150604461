import React from "react";
import './Field.css'

function Field (props) {
    return (
        <div className="field-container">
            <div className="field-title">
                {props.title}
            </div>
            <div className="field-value">
                {props.value}
            </div>
        </div>
    )
}
export default Field;