import React from "react";

import './App.css';
import Message from './components/Message/Message';
import HeaderBtn from "./components/HeaderBtn/HeaderBtn";
import Field from "./components/Field/Field";
import { generate_code_challenge, generateCodeVerifier, getRandomString } from "./components/utils";


class App extends React.Component {
    constructor(props) {
        super(props);
        const codeVerifiers = JSON.parse(localStorage.getItem('codeVerifiers')) || [];
        const states = JSON.parse(localStorage.getItem('states')) || [];
        const refreshToken = localStorage.getItem('refreshToken') || null;
        this.state = {
            state: states[states.length -1],
            verifier: codeVerifiers[codeVerifiers.length -1],
            refreshToken: refreshToken,
            authCode: new URLSearchParams(window.location.search).get("code"),
            authState: new URLSearchParams(window.location.search).get("state"),
            accessToken: null,
            expiresAt: null,
        };
    }

    componentDidMount() {
        // verify it's a valid state
        const states = JSON.parse(localStorage.getItem('states')) || [];
        if (this.state.code && !states.includes(this.state.state)) {
            alert("invalid state");
        }
    }

    handleGenerate () {
        const codeVerifiers = JSON.parse(localStorage.getItem('codeVerifiers')) || [];
        const states = JSON.parse(localStorage.getItem('states')) || [];
        const verifier = generateCodeVerifier();
        const state = getRandomString(48);
        localStorage.setItem('codeVerifiers', JSON.stringify([...codeVerifiers, verifier]));
        localStorage.setItem('states', JSON.stringify([...states, state]));
        this.setState({
            verifier: verifier,
            state: state,
        });
    }

    getToken(verifier) {
        // must use URLSearchParams for body
        return fetch(`${process.env.REACT_APP_AUTH_API}token`, {
            mode: 'cors',
            method: 'POST',
            body: new URLSearchParams({
                client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
                grant_type: "authorization_code",
                redirect_uri: process.env.REACT_APP_AUTH_CALLBACK,
                code_verifier: verifier,
                code: this.state.authCode,
            })
        })
    }

    getMessage() {
        if (!this.state.state || !this.state.verifier) {
            if (this.state.authCode) {
                return "Can't exchange authorization code without state and code verifier";
            }
            return 'Please generate login params to begin';
        } else if (!this.state.authCode) {
            return 'Please login to get an authorization code'
        } else if (!this.state.accessToken) {
            return 'Please exchange your authorization code for a token';
        }
        return 'Logged in! Exchange refresh token or reset to try again.';
    }

    handleLogin () {
        window.location.href = [
            `${process.env.REACT_APP_AUTH_LOGIN}login?`,
            `&redirect_uri=${encodeURIComponent(process.env.REACT_APP_AUTH_CALLBACK)}`,
            `&code_challenge=${generate_code_challenge(this.state.verifier)}`,
            '&code_challenge_method=S256',
            `&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}`,
            `&state=${this.state.state}`,
            '&response_type=code',
            '&grant_type=password',
            '&scope=openid',
            `&expires=${(new Date()).getTime() + 1000 * 60 * 30}`
        ].join('')
    }

    handleExchange () {
        let success = false;
        const verifierList = JSON.parse(localStorage.getItem('codeVerifiers')) || [];
        let tokenPromises = verifierList.map((verifier) => this.getToken(verifier));

        Promise.all(tokenPromises).then((responses) => {
            for (let response of responses) {
                if (response.status === 200) {  // only one promise will give a 200, so data declaration is safe
                    response.json().then((data) => {
                        this.setState({
                            accessToken: data['accessToken'],
                            refreshToken: data['refreshToken'],
                            expiresAt: this.getDate(data['expiresAt']),
                        });
                        localStorage.setItem('refreshToken', data['refreshToken']);
                    });
                    success = true;
                }
            }
            localStorage.removeItem('states');
            localStorage.removeItem('codeVerifiers');
            if (!success) {  // do a fresh login if exchange failed
                alert('Token exchange failed');
                // this.handleGenerate();
                // this.handleLogin();
            }
        });
        setTimeout(() => {this.handleRefresh()}, 30000);
    }

    handleRefresh () {
        fetch(`${process.env.REACT_APP_AUTH_API}token`, {
            mode: 'cors',
            method: 'POST',
            body: new URLSearchParams({
                client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
                grant_type: "refresh_token",
                refresh_token: localStorage.getItem('refreshToken'),
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    this.setState({
                        accessToken: data['accessToken'],
                        refreshToken: data['refreshToken'],
                        expiresAt: this.getDate(data['expiresAt']),
                    });
                    localStorage.setItem('refreshToken', data['refreshToken']);
                    setTimeout(() => {this.handleRefresh()}, 30000);
                });
            } else {
                alert('Refresh failed');
                // this.handleGenerate();
                // this.handleLogin();
            }
        })
    }

    handleReset () {
        localStorage.clear();
        window.location.href = window.location.origin;
    }

    handleDecode () {
        this.setState({
           accessToken: JSON.stringify(JSON.parse(atob(this.state.accessToken.split('.')[1])),null,2),
           refreshToken: JSON.stringify(JSON.parse(atob(this.state.refreshToken.split('.')[1])),null,2),
        });
    }

    getDate (utcSeconds) {
        let date = new Date(0);
        date.setUTCSeconds(utcSeconds);
        return date.toString();
    }

    render() {
        const states = JSON.parse(localStorage.getItem('states')) || [];

        return (
            <div className="app">
                <Message message={this.getMessage()}/>
                <div className="header">
                    <HeaderBtn
                        title="Generate Login Params"
                        onClick={() => this.handleGenerate()}
                    />
                    <HeaderBtn
                        title="Login"
                        onClick={() => this.handleLogin()}
                        disabled={!(this.state.verifier && this.state.state)}
                    />
                    <HeaderBtn
                        title="Exchange Token"
                        onClick={() => this.handleExchange()}
                        disabled={!this.state.authCode}
                    />
                    <HeaderBtn
                        title="Decode Tokens"
                        onClick={() => this.handleDecode()}
                        disabled={!(this.state.accessToken && this.state.accessToken.startsWith('ey'))}
                    />
                    <HeaderBtn
                        title="Refresh Token"
                        onClick={() => this.handleRefresh()}
                        disabled={!this.state.refreshToken}
                    />
                    <HeaderBtn
                        title="Reset"
                        onClick={() => this.handleReset()}
                    />
                </div>
                <div className="fields">
                    <Field title="State" value={this.state.state}/>
                    <Field title="Verifier" value={this.state.verifier}/>
                    <Field title="Code Challenge" value={generate_code_challenge(this.state.verifier)}/>
                    <br/>
                    <Field title="Auth Code" value={this.state.authCode}/>
                    <Field title="Auth State" value={this.state.authState}/>
                    <Field title="States Match" value={
                        this.state.authState ? states.includes(this.state.authState).toString(): null
                    }/>
                    <br/>
                    <Field title="Access Token" value={this.state.accessToken}/>
                    <Field title="Refresh Token" value={this.state.refreshToken}/>
                    <Field title="Expires At" value={this.state.expiresAt}/>
                </div>
            </div>
        );
    }
}

export default App;
